import React from "react"

import NavigationLayout from "../components/layout/mainLayout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <NavigationLayout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </NavigationLayout>
)

export default NotFoundPage
